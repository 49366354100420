<template>
  <el-row @click="pantallaCompleta()" id="rowprincipal" :gutter="10">
    <router-view />
  </el-row>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
  mounted() {
  const params = new URLSearchParams(document.location.search);

  // Si hay parámetros en la URL, guárdalos en localStorage
  if (params.toString()) {
    localStorage.params = JSON.stringify(Object.fromEntries(params));
  }

  // Recupera los params almacenados si la URL no tiene parámetros
  const storedParams = localStorage.params && localStorage.params !== "undefined" 
    ? JSON.parse(localStorage.params) 
    : {};
  
  // Guarda los params en el estado y en settings
  this.setParams(storedParams);
  Object.entries(storedParams).forEach(([key, value]) => {
    this.settings[key] = value;
  });

  this.verifyLogin();
  if(this.settings.centro_id && this.settings.centro_id != null) {
    this.settings.selcentro = false;
  }
  if(!this.settings.selcentro) {
    this.settings.geolocalizar = false;
  }
  if(this.settings.token) {
    this.settings.usar_nfc = false;
    this.settings.solicitar_pin = false;
  }
},
  computed: mapState([
    'settings'
  ]),
  methods: {
    ...mapMutations([
      'setParams', 'setColor'
    ]),
    verifyLogin() {
      if (localStorage.getItem('token')) {
        console.log(localStorage.getItem('token'))
      } else {
        this.$router.push('/login');
      }
    },
    pantallaCompleta() {
      if(this.settings.pantalla_completa) {
      document.documentElement.requestFullscreen()
      }
    }
  }
}
</script>
<style>
* {
  font-family: 'Exo 2', sans-serif;
}

body {
  margin: 0;
}

#rowprincipal {
  display: flex;
  flex-wrap: wrap;
  margin: 0 !important;
  align-items: center;
}

::-webkit-scrollbar {
  width: 8px;
  /* Tamaño del scroll en vertical */
  height: 8px;
  /* Tamaño del scroll en horizontal */
  transition: 0.5s
}

/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

/* Ponemos un color de fondo y redondeamos las esquinas del track */
::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

/* Cambiamos el fondo cuando esté en active o hover */
::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}


@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.apply-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

h2 {
  color: #727272;
  font-weight: 500;

  &.instrucciones {
    transition: 0.5s;

    &.red {
      color: rgba(255, 0, 0, 0.699);
    }
  }
}

.el-loading-spinner .path {
  stroke: #0984e3;
}

.el-switch__label.is-active {
  color: #303133;
}

.el-switch.is-checked .el-switch__core {
  border-color: #0984e3;
  background-color: #0984e3;
}
</style>