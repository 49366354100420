<template>
    <el-col id="colderecha" :xs="24" :md="12" :cols="12">
        <el-main id="mainopciones">
            <h2 style="margin-bottom: 10px;">Enlaza tu tarjeta</h2>
            <img :src="require('@/assets/changepw.png')" style="max-width: 40%; margin:auto;" />
            <h2 style="margin-bottom: 10px; text-align:justify;">Necesitamos que enlaces una tarjeta para poder fichar</h2>
            <p style="text-align: justify;">Con tu tarjeta, a partir de ahora podrás confirmar todas las
                acciones de forma rápida: fichar entradas, pausas, ausencias... Utiliza una tarjeta facilitada por tu
                responsable.</p>
            <div>
                <div @click="enlazarTarjeta" style="background: #409eff21;">
                    <div style="padding: 10px">
                        <el-button style="background-color: #409EFF; border-color: #409EFF" type="primary" circle>
                            <font-awesome-icon style="font-size:1.5rem;" :icon="['fas', 'lock']" />
                        </el-button>
                        <b style="color: #409EFF">Registrar tarjeta</b>
                    </div>
                </div>
            </div>
        </el-main>
    </el-col>
</template>

<script>
import { notify } from '@/swal';
import { mapMutations, mapState } from 'vuex';

export default {
    name: 'CreateCard',
    data() {
        return {
            nfcController: null, // AbortController para gestionar NFC
        };
    },
    computed: {
        ...mapState(['datosemp', 'optionSelected', 'datoscolor', 'registros', 'teletrabajo', 'settings']),
    },
    methods: {
        ...mapMutations(['setMode', 'setInstrucciones']),
        generarTokenAleatorio(longitud = 16) {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';
    for (let i = 0; i < longitud; i++) {
        const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
        token += caracteres.charAt(indiceAleatorio);
    }
    return token;
},
        async enlazarTarjeta() {
            if ('NDEFReader' in window) {
                try {
                    // Crear instancia de NDEFReader
                    // eslint-disable-next-line no-undef
                    const nfcReader = new NDEFReader();

                    // Crear AbortController
                    this.nfcController = new AbortController();
                    const signal = this.nfcController.signal;

                    // Notificar al usuario

                    let token = this.generarTokenAleatorio(32);
                    notify(true, 'Iniciando enlace', 'Pasa la tarjeta para enlazar', 'info', 3000);
                    // Iniciar escritura NFC
                    await nfcReader.write(
                        {
                            records: [
                                {
                                    recordType: 'text',
                                    data: token, // Información que deseas escribir
                                    lang: 'es', // Idioma del texto
                                },
                            ],
                        },
                        { signal }
                    );

                    // Notificar éxito
                    let resp = await fetch(this.settings.api_url + '/api/fichajes/registrarTarjeta', {
                        headers: { "Content-Type": "application/json" },
                        method: 'POST',
                        body: JSON.stringify({datosemp: this.datosemp, token})
                    });
                    if(resp.status == 200) {
                        notify(true, 'Enlace exitoso', 'Ya puedes utilizar tu tarjeta', 'success', 3000);
                    }
                    // Redirigir al índice
                    this.$router.push('/index');
                } catch (error) {
                    // Manejar errores
                    console.error('Error al escribir en NFC:', error);
                    if (error.name === 'AbortError') {
                        console.log('Escritura NFC cancelada.');
                    } else {
                        notify(true, 'Error', 'No se ha podido escribir en la tarjeta', 'error', 3000);
                    }
                }
            } else {
                console.error('NFC no es compatible con este dispositivo.');
                notify(true, 'Error', 'NFC no es compatible con este dispositivo', 'error', 3000);
            }
        },
    },
    beforeUnmount() {
        // Cancelar lectura/escritura NFC
        if (this.nfcController) {
            this.nfcController.abort();
            console.log('Escritura NFC cancelada.');
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono&display=swap');

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin transition {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

#mainopciones {
    text-align: center;

    @media(min-width: 990px) {
        max-width: 500px;
    }

    margin:auto;

    div {
        border-radius: 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: 0.5s;

        button {
            width: 50px;
            height: 50px;
            transition: 0.5s;

            span {
                i {
                    font-size: 1.5rem;
                }

                svg {
                    font-size: 1.5rem;
                }
            }
        }

        &.green {
            background: #a3cb3826;
            color: #A3CB38;

            button {
                background-color: #A3CB38;
                border-color: #A3CB38;
            }
        }

        &.yellow {
            background: #f9ca2426;
            color: #f9ca24;

            button {
                background-color: #f9ca24;
                border-color: #f9ca24;
            }
        }

        &.purple {
            background: #dfe6e9;
            color: #636e72;

            button {
                background-color: #636e72;
                border-color: #636e72;
            }
        }

        &.red {
            background: #ff475726;
            color: #ff4757;

            button {
                background-color: #ff4757;
                border-color: #ff4757;
            }
        }
    }
}

#mainselectortarea {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .contenedorbusqueda {
        @media(min-width: 480px) {
            width: 400px;
        }
    }

    .contenedorreceptores {
        @media(min-width: 480px) {
            width: 400px;
        }

        padding:10px;
        max-height: 50vh;
        overflow-y:auto;

        li {
            list-style: none;
            padding: 10px;
            background: #d5dae8;
            margin: 0px 0px 10px 0px;
            border-radius: 5px;
            color: #071c64;
            cursor: pointer;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                fill: #071c64;
                transition: 0.3s;
            }

            &:hover {
                background: #071c64;
                color: white;

                svg {
                    fill: white;
                }
            }

            &:active {
                background: #041038;
                color: white;
            }
        }

    }
}

#contenedorajustes {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: left;
    width: 50%;
    background: white;
    margin: auto;
    border-radius: 5px;
    position: relative;

    @media (max-width: 1600px) {
        width: 75%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }

    .datosusuario {
        display: flex;
        padding: 10px;
        border-radius: 10px;
        justify-content: flex-start;

        img {
            border-radius: 50%;
            margin-bottom: 10px;
            padding: 3px;
            margin: 10px 20px 10px 10px;
        }

        span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;

            h2 {
                font-weight: normal;
                margin: 0;
                margin-bottom: 0px;
            }

            h3 {
                font-weight: normal;
                margin: 0;
            }
        }
    }

    h3 {
        margin: 10px 0px 0px 0px;
    }

    div {
        display: flex;
        background: #ffffff;
        border-radius: 10px;
        margin-top: 10px;
        justify-content: center;
        margin-bottom: 10px;

        div {
            border-radius: 5px;
            width: 100%;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 5px;
            transition: 0.5s;

            button {
                width: 50px;
                height: 50px;
                transition: 0.5s;
            }
        }
    }
}

.botoncerrar {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px;
    border: 2px solid darkgray;
    color: darkgray;
    border-radius: 50%;
    font-size: 1.5rem;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    align-items: center;
    transition: 0.5s;

    &:hover {
        background: darkgray;
        color: white;
    }

    &:active {
        transition: 0s;
        background: rgb(104, 104, 104);
        color: white;
    }
}

h2 {
    color: #727272;
    font-weight: 500;
    margin-bottom: 10px;
}


.tarjetaempleado {
    width: 160px;
    height: 160px;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

ul.contenedorNumeros {
    display: flex;
    padding: 0px;
    margin: 0 auto;
    list-style: none;
    flex-wrap: wrap;
    max-width: 300px;
    justify-content: center;

    li {
        width: 80px;
        height: 80px;
        background-color: #f9f9f9;
        border: 1px solid #dedede;
        border-radius: 50%;
        display: flex;
        align-items: Center;
        justify-content: center;
        font-size: 1.5rem;
        margin: 5px;
        color: #585858;
        font-weight: 500;
        cursor: pointer;
        transition: .4s;

        &:hover {
            background-color: #dedede;
            border: 1px solid #a0a0a0;
        }

        &.danger {
            color: #F56C6C;
            background-color: rgb(253, 226, 226);
            border-color: #F56C6C;

            &:hover {
                background-color: #F56C6C;
                color: rgb(253, 226, 226);
            }
        }

        &.success {
            color: #67C23A;
            background-color: rgb(225, 243, 216);
            border-color: #67C23A;

            &:hover {
                color: rgb(225, 243, 216);
                background-color: #67C23A;
            }
        }
    }
}

.selectorcentro {
    width: 60%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    background-color: #f9f9f9;
    border: 1px solid #dedede;
    color: #585858;
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    transition: 1s;
    cursor: pointer;

    &.enabled {
        &:hover {
            background-color: #dedede;
            border-color: #f9f9f9;
        }
    }

    &.disabled {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        cursor: not-allowed;
    }
}

h1 {
    margin: auto;
    color: gray;
}

.selectorestado {
    border-radius: 50%;
    border: 3px solid transparent;
    background: rgba(0, 0, 0, 0.5);
    width: 50px;
    height: 50px;
    position: relative;
    opacity: 0;
    transition: 0.3s;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 1;
    }

    &.selected {
        opacity: 1;
    }

    &:active {
        background: rgba(0, 0, 0, 0.5);
    }
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.apply-shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

/* Tooltip container */
.tooltip {
    position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
    opacity: 0;
    width: 250px;
    background-color: rgba(0, 0, 0, 0.795);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 0px 0px 5px 5px;
    position: absolute;
    z-index: 99999;
    width: 180px;
    top: 150px;
    left: 50%;
    margin-left: -90px;
    transition: 0.5s;
    /* Use half of the width (120/2 = 60), to center the tooltip */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    opacity: 1;
}

.el-loading-mask {
    background-color: none;
}
</style>