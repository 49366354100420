<template>
    <el-col v-loading="loading" id="colderecha" :xs="24" :md="12" :cols="12">
        <el-main id="mainopciones">
            <div id="contenedorCards" v-if="!loading">
                <el-collapse-transition>
                    <div v-if="drawer == false" id="datosusuario">
                        <img :src="`https://www.gravatar.com/avatar/${datosemp.email}`" />
                        <span>
                            <h2 v-if="datosemp && datosemp.nombre">¡Hola, {{ datosemp.nombre.split(' ')[0] }}!</h2>
                            <h3><small><b>{{ horaActual }}</b> — {{ fechaActual }}</small></h3>
                            <small @click="$router.push('/settings')"
                                :style="`margin-top: 10px; color:${estadoHoras}`"><b>Ajustes</b></small>
                        </span>
                    </div>
                </el-collapse-transition>
                <template v-if="registros[0] && registros[0]['tipoRegistro'] != 'Fin' && !drawerfin">
                    <div id="contenedorCronometros" v-if="estadoHoras">
                        <div class="contador">
                            <el-progress :width="100" :color="estadoHoras" type="circle" :percentage="porcentajeHoras"
                                :show-text="false"></el-progress>
                            <span :style="`color:${estadoHoras}`"><b>{{
                                registros[0]['tipoRegistro'] == 'Pausa' ? tiempos.hh_pausa : tiempos.hh
                                    }}</b>h</span>
                        </div>
                        <div class="contador">
                            <el-progress :width="100" :color="estadoHoras" type="circle" :percentage="porcentajeMinutos"
                                :show-text="false"></el-progress>
                            <span :style="`color:${estadoHoras}`"><b>{{
                                registros[0]['tipoRegistro'] == 'Pausa' ? tiempos.mm_pausa : tiempos.mm
                                    }}</b>m</span>
                        </div>
                        <div class="contador">
                            <el-progress :width="100" :color="estadoHoras" type="circle"
                                :percentage="porcentajeSegundos" :show-text="false"></el-progress>
                            <span :style="`color:${estadoHoras}`"><b>{{
                                registros[0]['tipoRegistro'] == 'Pausa' ? tiempos.ss_pausa : tiempos.ss
                                    }}</b>s</span>
                        </div>
                    </div>
                </template>
                <template v-if="registros.length == 0">
                    <h2>Indica cómo quieres iniciar tu jornada</h2>
                    <div class="contenedorSelector" style="margin-bottom: 0px;">
                        <div @click="setTeletrabajo(false), validarRegistro('Inicio')"
                            :style="`background: ${datoscolor.colorfondobotones};`">
                            <el-button
                                :style="`background-color: ${datoscolor.colorbotones}; border-color: ${datoscolor.colorbotones}`"
                                type="primary" circle><font-awesome-icon :icon="['fas', 'right-to-bracket']" />
                            </el-button> <b :style="`color: ${datoscolor.colorbotones}`">Presencial</b>
                        </div>
                        <div class="green" @click="setTeletrabajo(true), validarRegistro('Inicio')">
                            <el-button type="success" circle><font-awesome-icon style="font-size:1.2rem;"
                                    :icon="['fas', 'house']" />
                            </el-button><b>Teletrabajo</b>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div v-if="!drawerfin" class="contenedorSelector" style="margin-bottom: 0px;"
                        :style="`${registros[0]['tipoRegistro'] != 'Fin' ? '' : 'background: none'}`">
                        <div v-if="this.registros[0]['tipoRegistro'] == 'Inicio' || this.registros[0]['tipoRegistro'] == 'Reanudacion'"
                            @click="drawer = !drawer" class="yellow">
                            <el-button type="primary" circle><font-awesome-icon style="font-size:1.5rem;"
                                    :icon="['fas', 'pause']" />
                            </el-button> <b>Pausa</b>
                        </div>
                        <div v-if="this.registros[0]['tipoRegistro'] == 'Pausa'"
                            @click="setTeletrabajo(false), validarRegistro('Reanudacion')"
                            :style="`background: ${datoscolor.colorfondobotones};`">
                            <el-button
                                :style="`width: 50px; height: 50px; background-color: ${datoscolor.colorbotones}; border-color: ${datoscolor.colorbotones}`"
                                type="primary" circle><font-awesome-icon style="font-size:1.5rem;"
                                    :icon="['fas', 'play']" />
                            </el-button> <b :style="`color: ${datoscolor.colorbotones}`">Reanudar</b>
                        </div>
                        <div v-if="this.registros[0]['tipoRegistro'] == 'Pausa'" class="green"
                            @click="setTeletrabajo(true), validarRegistro('Reanudacion')">
                            <el-button type="success" circle><font-awesome-icon style="font-size:1.2rem;"
                                    :icon="['fas', 'house']" />
                            </el-button><b>Teletrabajo</b>
                        </div>
                        <div v-if="this.registros[0]['tipoRegistro'] != 'Fin'" @click="finalizarJornada()" class="red">
                            <el-button type="success" circle><font-awesome-icon style="font-size:1.3rem;"
                                    :icon="['fas', 'stop']" />
                            </el-button> <b>Finalizar</b>
                        </div>
                        <div v-else>
                            <div
                                style="display:flex; flex-direction:column; margin: auto; align-items:center; justify-content:center;">
                                <el-result icon="success" :title="`Ya has finalizado tu jornada`"></el-result>
                            </div>
                        </div>
                    </div>
                    <el-collapse-transition>
                        <div v-if="drawer == true" class="contenedorSelector pauseList">
                            <div v-for="(pause, p) in settings.pauseList" :key="p" @click="validarRegistro(pause.value)"
                                class="yellow"
                                :style="p == settings.pauseList.length - 1 && settings.pauseList.length % 2 != 0 ? 'width: 100%' : ''">
                                <el-button type="primary" circle><font-awesome-icon style="font-size:1.5rem;"
                                        :icon="pause.icon" />
                                </el-button> <b>{{ pause.label }}</b>
                            </div>
                            <el-button type="warning" plain icon="el-icon-back" class="botonvolver"
                                @click="drawer = false"><b>Volver
                                    atrás</b></el-button>
                        </div>
                    </el-collapse-transition>
                    <el-collapse-transition>
                        <div v-if="drawerfin == true" class="contenedorSelector pauseList">
                            <div v-for="(pause, p) in finList" :key="p" @click="pause.selected = !pause.selected"
                                :class="{ 'red': !pause.selected, 'green': pause.selected }"
                                :style="p == finList.length - 1 && finList.length % 2 != 0 ? 'width: 100%' : ''">
                                <el-button type="primary" circle><font-awesome-icon style="font-size:1.3rem;"
                                        :icon="['fas', pause.icon]" />
                                </el-button> <b>{{ pause.label }}</b>
                            </div>
                            <div class="red" :style="'width: 100%'">
                                <b>Añadir comentario (Opcional)</b>
                                <el-input v-model="inputfinjornada" placeholder="Escribe un comentario"
                                    style="width: 100%"></el-input>
                                <el-button @click="validarRegistro(textoFinJornada)" type="danger"
                                    style="width: 100%;">Finalizar
                                    jornada</el-button>
                            </div>
                            <el-button type="danger" plain icon="el-icon-back" class="botonvolver red"
                                @click="drawerfin = false"><b>Volver
                                    atrás</b></el-button>
                        </div>
                    </el-collapse-transition>
                </template>
            </div>
        </el-main>
    </el-col>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { notify } from '@/swal';
import Swal from 'sweetalert2';
const moment = require('moment');
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es')
// import { notify } from '@/swal'
export default {
    name: 'SelectorOpciones',
    data() {
        return {
            loading: false,
            drawer: false,
            drawerfin: false,
            tiempos: {
                hh: 0,
                mm: 0,
                ss: 0,
                total: '00h 00m 00s',
                hh_pausa: 0,
                mm_pausa: 0,
                ss_pausa: 0,
                total_pausa: '00h 00m 00s',
            },
            finList: [],
            inputfinjornada: '',
        }
    },
    async mounted() {
        if (this.settings.ajustes_fin && this.settings.finList) {
            this.finList = this.settings.finList
        }
        this.loading = true;
        await this.obtenerRegistros();
        this.loading = false;
        setInterval(() => {
            this.fechaActual = moment().format('D') + ' de ' + moment().format('MMMM');
            this.horaActual = moment().format('HH:mm');
            this.calcularTiempos();
        }, 1000);
    },
    computed: {
        ...mapState([
            'datosemp', 'optionSelected', 'datoscolor', 'registros', 'teletrabajo', 'settings'
        ]),
        porcentajeHoras() {
            let tiempo = moment(this.datosemp['horasTrabajo'], 'HH:mm')
            let horastrabajo = tiempo.hour() * 60 + tiempo.minute();
            let horastrabajadas = this.tiempos.hh * 60 + this.tiempos.mm;
            if (!isNaN((horastrabajadas * 100) / horastrabajo)) {
                if ((horastrabajadas * 100) / horastrabajo > 100) {
                    return 100;
                }
                return (horastrabajadas * 100) / horastrabajo;
            }
            return 0;
        },
        porcentajeMinutos() {
            let tiempo = moment(this.datosemp['horasTrabajo'], 'HH:mm')
            let horastrabajo = tiempo.hours();
            let horastrabajadas = this.tiempos.hh
            if (horastrabajo == horastrabajadas) {
                return (this.tiempos.mm * 100) / tiempo.minute();
            } else {
                return (this.tiempos.mm * 100) / 60;
            }
        },
        porcentajeSegundos() {
            return (this.tiempos.ss * 100) / 60;
        },
        estadoHoras() {
            if (this.porcentajeHoras >= 100) {
                return '#27ae60';
            }
            if (this.registros && this.registros.length && this.registros[0]['tipoRegistro'] == 'Pausa') {
                return 'rgb(249, 202, 36)';
            } else {
                return this.datoscolor.colorbotones;
            }
        },
        textoFinJornada() {
            let texto = [];
            this.finList.map((item) => {
                if (item.selected) {
                    texto.push(item.value);
                }
            });
            if (this.inputfinjornada) {
                texto.push('Comentario: ' + this.inputfinjornada);
            }
            if (texto.length > 0) {
                texto = 'Fin::' + texto.join(';');
                return texto;
            }
            return 'Fin::Fin jornada';
        },
    },
    methods: {
        ...mapMutations(['setDatosEmp', 'setRegistros', 'validarRegistro', 'setTeletrabajo', 'RESET_STATE']),
        async finalizarJornada() {
            if (this.settings.confirmar_fin) {
                await Swal.fire({
                    title: "Confirmar fin",
                    text: "Vas a finalizar la jornada. Si lo haces, no podrás volver a fichar hasta tu próximo turno. ¿Deseas continuar?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: this.datoscolor.colorbotones,
                    cancelButtonColor: "#eb3b5a",
                    confirmButtonText: "Finalizar jornada",
                    cancelButtonText: "Cancelar",
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (this.settings.ajustes_fin) {
                            this.drawer = false;
                            this.drawerfin = !this.drawerfin;
                            return;
                        } else {
                            this.validarRegistro('Fin')
                        }
                    } else {
                        // Si el usuario cancela, se detiene la función.
                        notify(true, null, 'No se ha finalizado la jornada', 'info');
                    }
                });
            } else {
                if (this.settings.ajustes_fin) {
                    this.drawerfin = !this.drawerfin;
                    return;
                } else {
                    this.validarRegistro('Fin')
                }
            }
        },
        calcularTiempos() {
            if (this.registros && this.registros.length) {
                let segundosTotales = 0;
                let segundosPausas = 0;
                // let segundosInterrupcion = 0;
                let hora1, hora2;

                const getLastRegistroHour = () => moment(this.registros[this.registros.length - 1]['hora'], 'HH:mm:ss');
                const calculateSeconds = (h1, h2) => ((h1.hour() * 3600) + (h1.minutes() * 60) + h1.seconds()) - ((h2.hour() * 3600) + (h2.minutes() * 60) + h2.seconds());

                hora1 = moment();
                hora2 = getLastRegistroHour();
                segundosTotales = calculateSeconds(hora1, hora2);

                if (this.registros[0]['tipoRegistro'] == 'Reanudacion') {
                    for (let i = 0; i < this.registros.length - 1; i += 2) {
                        if (this.registros[i]['tipoRegistro'] == 'Reanudacion') {
                            hora1 = moment(this.registros[i]['hora'], 'HH:mm:ss');
                            hora2 = moment(this.registros[i + 1]['hora'], 'HH:mm:ss');
                            segundosTotales -= calculateSeconds(hora1, hora2);
                            // if(this.registros[i + 1]['motivo'] == 'Interrupción') {
                            // hora2 = moment(this.registros[i + 1]['hora'], 'HH:mm:ss');
                            //   if(calculateSeconds(hora1, hora2) > 900) {
                            //     segundosTotales += 900;
                            //   } else {
                            //     segundosPausas += calculateSeconds(hora1, hora2)
                            //   }
                            // }
                        }
                    }
                }

                if (this.registros[0]['tipoRegistro'] == 'Pausa') {
                    segundosTotales = 0;
                    for (let i = 0; i < this.registros.length; i += 2) {
                        if (this.registros[i]['tipoRegistro'] == 'Pausa') {
                            hora1 = moment(this.registros[i]['hora'], 'HH:mm:ss');
                            hora2 = moment(this.registros[i + 1]['hora'], 'HH:mm:ss');
                            segundosTotales += calculateSeconds(hora1, hora2);
                            hora1 = moment();
                            hora2 = getLastRegistroHour();
                            segundosPausas = ((hora1.hour() * 3600) + (hora1.minutes() * 60) + hora1.seconds()) - ((hora2.hour() * 3600) + (hora2.minutes() * 60) + hora2.seconds()) - segundosTotales;
                            // if(this.registros[i]['motivo'] == 'Interrupción') {
                            //   hora2 = moment(this.registros[i + 1]['hora'], 'HH:mm:ss');
                            // hora1 = moment(this.registros[i]['hora'], 'HH:mm:ss');
                            // console.log(calculateSeconds(hora1, hora2));
                            //   if(calculateSeconds(hora1, hora2) > 900) {
                            //     segundosInterrupcion + 900;
                            //   } else {
                            //     segundosPausas -= calculateSeconds(hora1, hora2)
                            //   }
                            // }
                        }
                    }
                }

                if (this.registros[0]['tipoRegistro'] == 'Fin') {
                    clearInterval(this.interval);
                    segundosTotales = 0;
                    for (let i = 0; i < this.registros.length - 1; i += 2) {
                        hora1 = moment(this.registros[i]['hora'], 'HH:mm:ss');
                        hora2 = moment(this.registros[i + 1]['hora'], 'HH:mm:ss');
                        segundosTotales += calculateSeconds(hora1, hora2);
                    }
                    hora1 = moment(this.registros[0]['hora'], 'HH:mm:ss');
                    hora2 = getLastRegistroHour();
                    segundosPausas = calculateSeconds(hora1, hora2) - segundosTotales;
                }

                this.tiempos.hh = Math.floor(segundosTotales / 3600);
                this.tiempos.mm = Math.floor((segundosTotales % 3600) / 60);
                this.tiempos.ss = segundosTotales % 60;
                this.tiempos.hh_pausa = Math.floor(segundosPausas / 3600);
                this.tiempos.mm_pausa = Math.floor((segundosPausas % 3600) / 60);
                this.tiempos.ss_pausa = segundosPausas % 60;
                this.tiempos.total = `${this.tiempos.hh.toString().padStart(2, '0')}h ${this.tiempos.mm.toString().padStart(2, '0')}m ${this.tiempos.ss.toString().padStart(2, '0')}s`;

                if (isNaN(this.tiempos.hh_pausa)) this.tiempos.hh_pausa = this.tiempos.hh;
                if (isNaN(this.tiempos.mm_pausa)) this.tiempos.mm_pausa = this.tiempos.mm;
                if (isNaN(this.tiempos.ss_pausa)) this.tiempos.ss_pausa = this.tiempos.ss;
                this.tiempos.total_pausa = `${this.tiempos.hh_pausa.toString().padStart(2, '0')}h ${this.tiempos.mm_pausa.toString().padStart(2, '0')}m ${this.tiempos.ss_pausa.toString().padStart(2, '0')}s`;

                if (!this.registros[0]['tipoRegistro']) {
                    this.tiempos.hh = 0;
                    this.tiempos.mm = 0;
                    this.tiempos.ss = 0;
                    this.tiempos.total = '00h 00m 00s';
                }
            }
        },
        async obtenerRegistros() {
            let resp = await fetch(this.settings.api_url + '/api/fichajes/obtenerRegistros', {
                headers: {
                    "Content-Type": "application/json"
                },
                method: 'POST',
                body: JSON.stringify({ dni: this.datosemp.dni })
            })
            if (resp.status == 200) {
                this.setRegistros(await resp.json());
                if(!this.settings.selcentro && this.registros[0] && this.registros[0].tipoRegistro == 'Fin') {
                    notify(true, null, 'Tu sesión se cerrará en 5 segundos.', 'info', 5000);
                        setTimeout(() => {
                            localStorage.removeItem('dni')
                            this.RESET_STATE();
                            this.$router.push('/login');
                        }, 5000)
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono&display=swap');

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin transition {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.boton {
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;

    button {
        width: 50px;
        height: 50px;

        span {
            i {
                font-size: 1.5rem;
            }

            svg {
                font-size: 1.5rem;
            }
        }
    }

    &.green {
        background: #a3cb3826;
        color: #A3CB38;

        button {
            background-color: #A3CB38;
            border-color: #A3CB38;
        }
    }

    &.yellow {
        background: #f9ca2426;
        color: #f9ca24;

        button {
            background-color: #f9ca24;
            border-color: #f9ca24;
        }
    }

    &.purple {
        background: #dfe6e9;
        color: #636e72;

        button {
            background-color: #636e72;
            border-color: #636e72;
        }
    }

    &.red {
        background: #ff475726;
        color: #ff4757;

        button {
            background-color: #ff4757;
            border-color: #ff4757;
        }
    }
}


.dni-switch {
    margin: 30px 0px;
}

.el-switch__label.is-active {
    color: #000000;
}

#mainopciones {
    text-align: center;

    #contenedorCards {
        width: 50%;
        margin: auto;
        transition: 0.5s;

        @media (max-width: 1600px) {
            width: 75%;
        }

        @media (max-width: 500px) {
            width: 100%;
        }

        #datosusuario {
            display: flex;
            padding: 10px;
            background: #ffffffb8;
            border: 3px solid #d3d3d361;
            border-radius: 10px;
            margin: auto;
            margin-bottom: 10px;
            justify-content: flex-start;
            transition: 0.5s;

            @media (min-width: 990px) {
                display: none
            }

            img {
                border-radius: 50%;
                margin-bottom: 10px;
                padding: 3px;
                margin: 10px 20px 10px 10px;
            }

            span {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: left;

                h2 {
                    font-weight: normal;
                    margin: 0;
                    margin-bottom: 0px;
                }

                h3 {
                    font-weight: normal;
                    margin: 0;
                }
            }
        }

        #contenedorCronometros {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-wrap: wrap;
            margin-bottom: 20px;

            .contador {
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
                background: white;
                border-radius: 50%;

                span {
                    position: absolute;
                    font-size: 2rem;
                    transition: 1s;
                }
            }
        }

        .contenedorSelector {
            display: flex;
            background: #ffffff;
            border-radius: 10px;
            margin: auto;
            justify-content: center;
            margin-bottom: 10px;

            &.pauseList {
                flex-wrap: wrap;

                div {
                    width: calc(50% - 30px);
                }

                .botonvolver {
                    width: 100%;
                    font-size: 1rem;
                    margin: 5px;
                    background-color: rgba(249, 202, 36, 0.15);
                    border: none;
                    color: rgb(249, 202, 36);

                    &.red {
                        background-color: #ffe4e6;
                        color: #ff4757;
                    }
                }
            }

            div {
                border-radius: 5px;
                width: 100%;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 5px;
                transition: 0.5s;

                button {
                    width: 50px;
                    height: 50px;
                    transition: 0.5s;

                    span {
                        i {
                            font-size: 1.5rem;
                        }

                        svg {
                            font-size: 1.5rem;
                        }
                    }
                }

                &.green {
                    background: #a3cb3826;
                    color: #A3CB38;

                    button {
                        background-color: #A3CB38;
                        border-color: #A3CB38;
                    }
                }

                &.yellow {
                    background: #f9ca2426;
                    color: #f9ca24;

                    button {
                        background-color: #f9ca24;
                        border-color: #f9ca24;
                    }
                }

                &.purple {
                    background: #dfe6e9;
                    color: #636e72;

                    button {
                        background-color: #636e72;
                        border-color: #636e72;
                    }
                }

                &.red {
                    background: #ff475726;
                    color: #ff4757;

                    button {
                        background-color: #ff4757;
                        border-color: #ff4757;
                    }
                }
            }
        }
    }
}

#mainselectortarea {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .contenedorbusqueda {
        @media(min-width: 480px) {
            width: 400px;
        }
    }

    .contenedorreceptores {
        @media(min-width: 480px) {
            width: 400px;
        }

        padding:10px;
        max-height: 50vh;
        overflow-y:auto;

        li {
            list-style: none;
            padding: 10px;
            background: #d5dae8;
            margin: 0px 0px 10px 0px;
            border-radius: 5px;
            color: #071c64;
            cursor: pointer;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                fill: #071c64;
                transition: 0.3s;
            }

            &:hover {
                background: #071c64;
                color: white;

                svg {
                    fill: white;
                }
            }

            &:active {
                background: #041038;
                color: white;
            }
        }

    }
}

#contenedorajustes {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: left;
    width: 50%;
    background: white;
    margin: auto;
    border-radius: 5px;
    position: relative;

    @media (max-width: 1600px) {
        width: 75%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }

    .datosusuario {
        display: flex;
        padding: 10px;
        border-radius: 10px;
        justify-content: flex-start;

        img {
            border-radius: 50%;
            margin-bottom: 10px;
            padding: 3px;
            margin: 10px 20px 10px 10px;
        }

        span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;

            h2 {
                font-weight: normal;
                margin: 0;
                margin-bottom: 0px;
            }

            h3 {
                font-weight: normal;
                margin: 0;
            }
        }
    }

    h3 {
        margin: 10px 0px 0px 0px;
    }

    div {
        display: flex;
        background: #ffffff;
        border-radius: 10px;
        margin-top: 10px;
        justify-content: center;
        margin-bottom: 10px;

        div {
            border-radius: 5px;
            width: 100%;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 5px;
            transition: 0.5s;

            button {
                width: 50px;
                height: 50px;
                transition: 0.5s;
            }
        }
    }
}

.botoncerrar {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px;
    border: 2px solid darkgray;
    color: darkgray;
    border-radius: 50%;
    font-size: 1.5rem;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    align-items: center;
    transition: 0.5s;

    &:hover {
        background: darkgray;
        color: white;
    }

    &:active {
        transition: 0s;
        background: rgb(104, 104, 104);
        color: white;
    }
}

h2 {
    color: #727272;
    font-weight: 500;
    margin-bottom: 10px;
}


.tarjetaempleado {
    width: 160px;
    height: 160px;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

ul.contenedorNumeros {
    display: flex;
    padding: 0px;
    margin: 0 auto;
    list-style: none;
    flex-wrap: wrap;
    max-width: 300px;
    justify-content: center;

    li {
        width: 80px;
        height: 80px;
        background-color: #f9f9f9;
        border: 1px solid #dedede;
        border-radius: 50%;
        display: flex;
        align-items: Center;
        justify-content: center;
        font-size: 1.5rem;
        margin: 5px;
        color: #585858;
        font-weight: 500;
        cursor: pointer;
        transition: .4s;

        &:hover {
            background-color: #dedede;
            border: 1px solid #a0a0a0;
        }

        &.danger {
            color: #F56C6C;
            background-color: rgb(253, 226, 226);
            border-color: #F56C6C;

            &:hover {
                background-color: #F56C6C;
                color: rgb(253, 226, 226);
            }
        }

        &.success {
            color: #67C23A;
            background-color: rgb(225, 243, 216);
            border-color: #67C23A;

            &:hover {
                color: rgb(225, 243, 216);
                background-color: #67C23A;
            }
        }
    }
}

.selectorcentro {
    width: 60%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    background-color: #f9f9f9;
    border: 1px solid #dedede;
    color: #585858;
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    transition: 1s;
    cursor: pointer;

    &.enabled {
        &:hover {
            background-color: #dedede;
            border-color: #f9f9f9;
        }
    }

    &.disabled {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        cursor: not-allowed;
    }
}

h1 {
    margin: auto;
    color: gray;
}

.selectorestado {
    border-radius: 50%;
    border: 3px solid transparent;
    background: rgba(0, 0, 0, 0.5);
    width: 50px;
    height: 50px;
    position: relative;
    opacity: 0;
    transition: 0.3s;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 1;
    }

    &.selected {
        opacity: 1;
    }

    &:active {
        background: rgba(0, 0, 0, 0.5);
    }
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.apply-shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

/* Tooltip container */
.tooltip {
    position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
    opacity: 0;
    width: 250px;
    background-color: rgba(0, 0, 0, 0.795);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 0px 0px 5px 5px;
    position: absolute;
    z-index: 99999;
    width: 180px;
    top: 150px;
    left: 50%;
    margin-left: -90px;
    transition: 0.5s;
    /* Use half of the width (120/2 = 60), to center the tooltip */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    opacity: 1;
}

.el-loading-mask {
    background-color: none;
}
</style>