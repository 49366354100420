<template>
    <el-col v-show="!geoloading && !usenfc" v-loading="loading" id="colderecha" :xs="24" :md="12" :cols="12">
        <el-main id="mainpinpad"
            v-show="mode == 'dni' || settings.solicitar_pin == true && (mode == 'pin' || mode == 'cambiarPin' || mode == 'crearPin')"
            :class="{ 'apply-shake': shake }">
            <h2 :class="shake ? 'instrucciones red' : 'instrucciones'">{{ instrucciones }}</h2>
            <!-- <img class="avatar" v-if="mode == 'pin'" style="" :src="`https://www.gravatar.com/avatar/${datos.email}`" /> -->
            <div id="contenedorSalida">
                <div v-for="(salida, s) in salida" :key="s">{{ mode == "pin" || (mode == "cambiarPin" || mode ==
                    'crearPin')
                    ?
                    "*"
                    : salida }}</div>
            </div>
            <ul class="contenedorNumeros">
                <li v-for="(boton, b) in botones" :key="b" @click="anadirContenido(boton)" :class="boton.tipo">
                    <font-awesome-icon v-if="boton.icon" :icon="boton.icon" />
                    <p v-else>{{ boton.valor }}</p>
                </li>
            </ul>
            <div v-if="mode == 'dni' && settings.selcentro" class="dni-switch">
                <el-switch v-model="checkGuardar" active-text="Guardar DNI para iniciar sesión automáticamente" />
            </div>
            <div v-if="settings.selcentro && mode == 'pin' && (optionSelected == '' || optionSelected == 'Reanudacion')"
                class="dni-switch">
                <el-switch v-model="switchteletrabajo" active-text="Fichar entrada como teletrabajo" />
            </div>
            <div v-if="mode == 'pin' && instrucciones == 'Introduce el PIN para continuar'" class="dni-switch">
                <!-- <p @click="$router.push('/recover')" :style="`cursor: pointer; margin: 0px;`"><b><font-awesome-icon
                            :icon="['fas', 'lock']" />
                        ¿Olvidaste tu PIN?</b></p> -->
            </div>
        </el-main>
        <el-main style="text-align: center;" v-show="mode == 'validate'">
            <h2>Iniciando sesión...</h2>
        </el-main>
    </el-col>
    <el-col v-show="geoloading" id="colderecha" :xs="24" :md="12" :cols="12">
        <button v-if="buscando" id="blu">
            <font-awesome-icon :icon="['fas', 'location-dot']" />
        </button>
    </el-col>
    <el-col v-show="usenfc" id="colderecha" :xs="24" :md="12" :cols="12">
        <button id="blu">
            <font-awesome-icon :icon="['far', 'id-card']" />
        </button>
        <h2 style="text-align: center;">Inserte tarjeta</h2>
        <center><el-button @click="usenfc = false">Introducir manualmente</el-button><el-button v-if="errornfc"
                @click="activarNFC">Activar NFC</el-button></center>
    </el-col>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { notify } from '@/swal'
import Swal from 'sweetalert2';
export default {
    name: 'PinPad',
    data() {
        return {
            salida: [],
            checkGuardar: false,
            oldmode: "",
            loading: false,
            shake: false,
            geoloading: false,
            buscando: false,
            switchteletrabajo: false,
            token: null,
            pin: '',
            step: 1,
            usenfc: false,
            errornfc: false,
            nfcController: null, // Guardar el AbortController
            botones: [
                {
                    valor: 1,
                    texto: 1,
                    tipo: "primary",
                },
                {
                    valor: 2,
                    texto: 2,
                    tipo: "primary",
                },
                {
                    valor: 3,
                    texto: 3,
                    tipo: "primary",
                },
                {
                    valor: 4,
                    texto: 4,
                    tipo: "primary",
                },
                {
                    valor: 5,
                    texto: 5,
                    tipo: "primary",
                },
                {
                    valor: 6,
                    texto: 6,
                    tipo: "primary",
                },
                {
                    valor: 7,
                    texto: 7,
                    tipo: "primary",
                },
                {
                    valor: 8,
                    texto: 8,
                    tipo: "primary",
                },
                {
                    valor: 9,
                    texto: 9,
                    tipo: "primary",
                },
                {
                    valor: "Del",
                    tipo: "danger",
                    icon: ['fas', 'arrow-left'],
                },
                {
                    valor: 0,
                    texto: 0,
                    tipo: "primary",
                },
                {
                    valor: "Intro",
                    tipo: "success",
                    icon: ['fas', 'arrow-right'],
                },
            ],
        }
    },
    async mounted() {
        this.usenfc = this.settings.usar_nfc;
        if (this.mode == 'pin' && this.settings.solicitar_foto && !this.photoData) {
            this.$router.push('/camera');
        }
        window.addEventListener('keyup', this.teclaPresionada);
        if (this.settings.solicitar_pin) {
            if (localStorage.dni && !this.datosemp.dni) {
                this.checkGuardar = true;
                this.setMode('validate');
                this.salida = localStorage.dni;
                this.validarDNI();
            }
        } else {
            if (this.mode == 'dni' && !this.datosemp.dni) {
                this.setMode('validate');
                this.token = this.params.token;
                this.validarDNI();
            }
            if (this.mode == 'pin' && this.datosemp.token) {
                this.token = this.datosemp.token;
                this.validarDNI();
            }
        }
        this.switchteletrabajo = this.teletrabajo;
        this.salida = [];
        if(this.usenfc) {
        if ('NDEFReader' in window) {
            try {
                // eslint-disable-next-line no-undef
                const nfcReader = new NDEFReader();
                const controller = new AbortController();
                this.nfcController = controller; // Guardar el AbortController

                await nfcReader.scan({ signal: controller.signal });

                nfcReader.onreading = (event) => {
                    let detected = false;
                    for (const record of event.message.records) {
                        const texto = new TextDecoder().decode(record.data);
                        if (!detected && texto) {
                            this.salida = texto;
                            if(this.mode == 'dni') {
                            this.setMode('validate');
                            this.validarDNI(); // Llama a la función de validación
                            this.setMode('dni');
                            } else {
                            this.validarDNI(); // Llama a la función de validación
                            }
                            detected = true;
                        }
                    }
                };
            } catch (error) {
                console.error('Error en la lectura NFC:', error);
                this.errornfc = true;
                notify(true, 'Error', 'Ocurrió un error al intentar leer NFC.', 'error', 3000);
            }
        } else {
            console.error('NFC no está soportado en este dispositivo.');
        }
    }

    },
    watch: {
        checkGuardar() {
            this.setDniGuardado(this.checkGuardar);
        },
        switchteletrabajo() {
            this.setTeletrabajo(this.switchteletrabajo);
        }
    },
    beforeUnmount() {
        window.removeEventListener('keyup', this.teclaPresionada);
        // Si se está utilizando NFC, cancela la lectura
        // Detener la lectura NFC si el AbortController existe
        if (this.nfcController) {
            this.nfcController.abort();
            console.log('Lectura NFC cancelada.');
        }
    },
    computed: mapState([
        'datosemp', 'optionSelected', 'datoscolor', 'mode', 'instrucciones', 'teletrabajo', 'settings', 'params', 'photoData', 'settings'
    ]),
    methods: {
        ...mapMutations(['setDatosEmp', 'setMode', 'validarRegistro', 'setInstrucciones', 'setDniGuardado', 'setTeletrabajo', 'setPhotoData', 'RESET_STATE']),
        async activarNFC() {
            try {
                // Crear una instancia del lector NFC
                // eslint-disable-next-line no-undef
                const nfcReader = new NDEFReader();

                // Iniciar el escaneo para solicitar permisos
                await nfcReader.scan();

                // Detener el escaneo justo después de que se otorguen los permisos
                console.log("Permisos de NFC otorgados.");

                // No hay un método 'stop', pero podemos quitar el evento listener
                // Añadimos un listener temporal que será removido inmediatamente después de ser añadido
                const handler = (event) => {
                    console.log("Escaneo NFC recibido, pero no lo necesitamos." + event);
                };

                // Agregar el listener
                nfcReader.addEventListener("reading", handler);

                // Remover el listener para que no siga escaneando
                nfcReader.removeEventListener("reading", handler);

                // Opcional: recargar la página si es necesario
                window.location.reload();
            } catch (error) {
                console.error("Error al intentar escanear NFC:", error);
            }
        },
        detectarDispositivo() {
            const userAgent = navigator.userAgent || window.opera;

            // Detectar si es un dispositivo móvil
            if (/android/i.test(userAgent)) {
                return "Android";
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }
            // Si no coincide con ningún móvil, es probable que sea un escritorio
            return "Escritorio";
        },
        async getLocation() {
            if (!("geolocation" in navigator)) {
                alert("Tu navegador no soporta el acceso a la ubicación. Intenta con otro");
                return "Geolocalización no soportada";
            }

            const obtenerUbicacion = () => {
                return new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject, {
                        enableHighAccuracy: true,
                        maximumAge: 0,
                        timeout: 10000
                    });
                });
            };

            try {
                this.geoloading = true;
                this.buscando = true;
                const ubicacion = await obtenerUbicacion();
                this.geoloading = false;
                this.buscando = false;
                const coordenadas = ubicacion.coords;
                return `${coordenadas.latitude}, ${coordenadas.longitude}`;
            } catch (err) {
                console.log(err);
                this.geoloading = false;
                this.buscando = false;
                switch (err.code) {
                    case err.PERMISSION_DENIED:
                        return "Error: Permiso denegado";
                    case err.POSITION_UNAVAILABLE:
                        return "Error: Posición no disponible";
                    case err.TIMEOUT:
                        return "Error: Tiempo de espera agotado";
                    default:
                        return "Error desconocido";
                }
            }
        },
        async validarDNI() {
            if (this.mode == "dni" || this.mode == "validate") {
                if (typeof (this.salida) === "object") {
                    this.salida = this.salida.join("");
                }
                if (this.salida.length == 7 || this.salida.length == 8 || this.mode == 'validate') {
                    let data = {
                        'dni': this.salida,
                        'centro': !this.settings.selcentro ? this.appcentro : null,
                        'token': this.token,
                        'solicitar_pin': this.settings.solicitar_pin,
                        'selcentro': this.settings.selcentro,
                        'centro_id': this.settings.centro_id,
                        'centro_relativo': this.settings.centro_relativo,
                    }
                    this.loading = true;
                    let resp = await fetch(this.settings.api_url + '/api/fichajes/buscarEmpleado', {
                        headers: { "Content-Type": "application/json" },
                        method: 'POST',
                        body: JSON.stringify(data)
                    });
                    this.loading = false;
                    if (resp.status == 200) {
                        resp = await resp.json();
                        notify(true, null, `Bienvenid@, ${resp.nombre.split(' ')[0]}`, 'success')
                        this.datos = resp.datosemp;
                        this.dni = this.salida;
                        if (this.checkGuardar) {
                            localStorage.dni = this.salida;
                        }
                        this.setDatosEmp(resp);
                        if(!this.datosemp.centro.requerir_baliza) {
                            this.settings.requerir_baliza = false;
                        }
                        if (!this.settings.usar_nfc && !this.datosemp.pin && this.settings.solicitar_pin) {
                            this.$router.push('/createpin');
                            return true;
                        }
                        if (this.settings.usar_nfc && !this.datosemp.tarjetas && this.settings.solicitar_pin) {
                            this.$router.push('/createcard');
                            return true;
                        }
                        this.$router.push('/index');
                        // if (resp.datosemp.length == 1) {
                        // this.datos = resp.datosemp[0];
                        // this.inicializarDatos();
                        // }
                        // if (resp.datosemp.length > 1) {
                        //     this.cambiarModo("centro")
                        //     this.instrucciones = "Selecciona un centro"
                        //     this.numCentros = resp.datosemp.length;
                        // }
                    } else if (resp.status == 400) {
                        this.shakeAnimation();
                        notify(true, null, `Empleado no encontrado`, 'warning')
                    } else {
                        notify(true, null, `No se ha establecido conexión con el servidor`, 'error')
                    }
                } else {
                    this.shakeAnimation();
                }
                this.salida = [];
            } else if (this.mode == 'pin') {
                this.loading = true;
                if (typeof (this.salida) === "object") {
                    this.salida = this.salida.join("");
                }
                let detalle = '';
                let ubicacion = null;
                if (this.settings.geolocalizar) {
                    ubicacion = await this.getLocation();
                    let continuar = true;
                    if (ubicacion.startsWith('Error')) {
                        this.geoloading = true;
                        await Swal.fire({
                            title: "No hemos podido ubicarte",
                            text: "¿Quieres fichar sin ubicación? Por favor, comprueba que has activado el permiso para geolocalizarte.",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: this.datoscolor.colorbotones,
                            cancelButtonColor: "#eb3b5a",
                            confirmButtonText: "Fichar sin ubicación",
                            cancelButtonText: "Cancelar",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                detalle += 'Fichaje sin ubicación aceptado por el usuario. '
                                notify(true, null, 'Se añadirá un nuevo registro sin ubicación', 'info');
                                this.geoloading = false;
                            } else {
                                // Si el usuario cancela, se detiene la función.
                                notify(true, null, 'No se ha añadido ningún registro', 'info');
                                continuar = false;
                                return; // Cancela la ejecución del resto de la función.
                            }
                        });
                    } else {
                        if (this.settings.requerir_baliza) {
                            let ubicaciondividida = ubicacion.split(', ');
                            let distancia = this.calcularDistancia(this.datosemp.centro.latitud, this.datosemp.centro.longitud, ubicaciondividida[0], ubicaciondividida[1]);
                            distancia = distancia.toFixed(2);
                            if (distancia > 0.2) {
                                this.geoloading = true;
                                await Swal.fire({
                                    title: "Fichaje fuera de rango",
                                    text: "Estás fichando fuera de la zona permitida. ¿Deseas continuar? " + distancia,
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: this.datoscolor.colorbotones,
                                    cancelButtonColor: "#eb3b5a",
                                    confirmButtonText: "Fichar de todos modos",
                                    cancelButtonText: "Cancelar",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        detalle += 'Fichaje fuera de rango (' + distancia + 'km) aceptado por el usuario.'
                                        notify(true, null, 'Se añadirá un nuevo registro fuera de rango', 'info');
                                        this.geoloading = false;
                                    } else {
                                        // Si el usuario cancela, se detiene la función.
                                        notify(true, null, 'No se ha añadido ningún registro', 'info');

                                        continuar = false;
                                        return; // Cancela la ejecución del resto de la función.
                                    }
                                });
                            }
                        }
                    }
                    if (!continuar) {
                        this.$router.push('/index');
                        this.loading = false;
                        return false;
                    }
                }
                let data = {
                    opcion: this.optionSelected,
                    dni: this.datosemp.dni,
                    pin: this.salida,
                    teletrabajo: this.teletrabajo,
                    ubicacion,
                    detalle,
                    dispositivo: this.detectarDispositivo(),
                    solicitar_pin: this.settings.solicitar_pin,
                    token: this.datosemp.token,
                    solicitar_foto: this.settings.solicitar_foto,
                    foto: this.photoData,
                    centro: this.datosemp.centro.nombre,
                }
                let resp = await fetch(this.settings.api_url + '/api/fichajes/validarPIN', {
                    headers: { "Content-Type": "application/json" },
                    method: 'POST',
                    body: JSON.stringify(data)
                });
                this.salida = [];
                if (resp.status == 200) {
                    this.setPhotoData(false);
                    this.$router.push('/index');
                    if (!this.settings.selcentro && !data.opcion.startsWith('Fin')) {
                        notify(true, null, 'Tu sesión se cerrará en 5 segundos.', 'info', 5000);
                        setTimeout(() => {
                            localStorage.removeItem('dni')
                            this.RESET_STATE();
                            this.$router.push('/login');
                        }, 5000)
                    }
                }
                if (resp.status == 400) {
                    if (this.settings.solicitar_pin) {
                        this.shakeAnimation();
                    } else {
                        this.$router.push('/index');
                        notify(true, null, 'Ha ocurrido un error. Vuelve a intentarlo.', 'error')
                    }
                }
                else if (resp.status == 500) {
                    console.log(resp);
                    let mensaje = await resp.json();
                    notify(true, null, mensaje, 'error')
                    this.$router.push('/index');
                }
                this.loading = false;
            }
            else if (this.mode == 'crearPin') {
                if (typeof (this.salida) === "object") {
                    this.salida = this.salida.join("");
                }
                if (this.salida.length != 4) {
                    this.salida = [];
                    notify(true, null, `Introduce un PIN de 4 dígitos`, 'warning')
                    return false;
                }
                if (this.step == 2) {
                    if (this.salida != this.pin) {
                        notify(true, null, 'El PIN introducido no coincide', 'warning');
                        this.salida = [];
                        return false;
                    }
                    let data = {
                        dni: this.datosemp.dni,
                        pin: this.salida
                    }
                    this.loading = true;
                    let resp = await fetch(this.settings.api_url + '/api/fichajes/crearPIN', {
                        headers: { "Content-Type": "application/json" },
                        method: 'POST',
                        body: JSON.stringify(data)
                    });
                    if (resp.status == 200) {
                        notify(true, null, 'Tu PIN se ha creado correctamente', 'success')
                        this.$router.push('/index')
                    } else {
                        notify(true, null, 'Ha ocurrido un error creando tu PIN', 'error')
                        this.shakeAnimation()
                        this.$router.push('/createpin')
                    }
                    this.loading = false;
                }
                if (this.step == 1) {
                    this.step = 2;
                    this.setInstrucciones('Confirma tu nuevo PIN')
                    this.pin = this.salida;
                    this.salida = [];
                }
            }
            else if (this.mode == 'cambiarPin') {
                if (typeof (this.salida) === "object") {
                    this.salida = this.salida.join("");
                }
                this.validarRegistro('cambiarPin::' + this.salida);
                this.salida = [];
            }
        },
        shakeAnimation() {
            this.shake = true;
            setTimeout(() => {
                this.shake = false;
            }, 820); // timeout value depending on the duration of the animation
        },
        teclaPresionada(e) {
            if (this.mode == 'dni' || this.mode == 'pin' || this.mode == 'crearPin' || this.mode == 'cambiarPin') {
                if (!isNaN(e.key) && e.key <= 9 && e.key != '0') {
                    this.anadirContenido(this.botones[e.key - 1]);
                }
                if (e.key == "Enter") {
                    e.preventDefault();  // Evita el comportamiento por defecto
                    this.anadirContenido(this.botones[11]);
                }
                if (e.key == "Backspace") {
                    this.anadirContenido(this.botones[9]);
                }
                if (e.key == '0') {
                    this.anadirContenido(this.botones[10]);
                }
            }
        },
        anadirContenido(boton) {
            if (isNaN(boton.valor)) {
                if (boton.valor == "Del") {
                    if (this.salida.length == 0 && this.mode != 'dni') {
                        this.$router.back();
                    }
                    this.salida = []
                } else if (boton.valor == "Intro") {
                    if (!this.loading) {
                        this.validarDNI();
                    }
                }
            } else {
                if (this.salida.length < 8) {
                    this.salida.push(boton.valor);
                } else {
                    notify(true, null, 'Has superado el límite de caracteres', 'warning', 3000);
                }
            }
        },
        calcularDistancia(lat1, lon1, lat2, lon2) {
            // Convertir grados a radianes
            const radLat1 = lat1 * Math.PI / 180;
            const radLon1 = lon1 * Math.PI / 180;
            const radLat2 = lat2 * Math.PI / 180;
            const radLon2 = lon2 * Math.PI / 180;

            // Aplicar la fórmula del haversine
            const distancia = Math.acos(
                (Math.sin(radLat1) * Math.sin(radLat2)) +
                (Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radLon1 - radLon2))
            );

            // Convertir de radianes a kilómetros
            const distanciaKm = distancia * 57.29577951 * 111.302;
            return distanciaKm;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono&display=swap');

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin transition {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

#blu {
    @include vertical-align;
    @include transition;
    position: relative;
    width: 80px;
    height: 80px;
    font-size: 2em;
    display: block;
    margin: auto;
    text-align: center;
    outline: none;
    color: white;

    border-radius: 50px;
    border: 0;
    cursor: pointer;

    i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: auto;
        z-index: 1000;
    }

    /*animation 2*/
    background: rgb(30 144 255);
    -webkit-animation: base 1s infinite;

    @keyframes base {
        from {
            background: rgb(30 144 255);
        }

        to {
            background: rgb(30 144 255 / 30%);
        }
    }

    &:after {
        position: absolute;
        content: "";
        background: rgb(30 144 255);
        width: 50px;
        height: 50px;
        border-radius: 50px;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;

        /*animation 3*/
        opacity: 0;
        transform: scale(0, 0);
        z-index: 100;
        -webkit-animation: inline 1s infinite;

        @keyframes inline {
            from {
                opacity: 0;
                transform: scale(0, 0);
            }

            to {
                opacity: 1;
                transform: scale(1, 1);
            }
        }

    }


    &:before {
        position: absolute;
        @include transition;
        content: "";
        width: 80px;
        height: 80px;
        border: 2px solid rgb(30 144 255);
        border-radius: 50px;

        top: -2px;
        left: -2px;

        /*animation 1*/
        transform: scale(1, 1);
        opacity: 1;
        -webkit-animation: outline 1s infinite;

        @keyframes outline {
            from {
                transform: scale(1, 1);
                opacity: 1;
            }

            to {
                transform: scale(2, 2);
                opacity: 0;
            }
        }
    }

}

.boton {
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;

    button {
        width: 50px;
        height: 50px;

        span {
            i {
                font-size: 1.5rem;
            }

            svg {
                font-size: 1.5rem;
            }
        }
    }

    &.green {
        background: #a3cb3826;
        color: #A3CB38;

        button {
            background-color: #A3CB38;
            border-color: #A3CB38;
        }
    }

    &.yellow {
        background: #f9ca2426;
        color: #f9ca24;

        button {
            background-color: #f9ca24;
            border-color: #f9ca24;
        }
    }

    &.purple {
        background: #dfe6e9;
        color: #636e72;

        button {
            background-color: #636e72;
            border-color: #636e72;
        }
    }

    &.red {
        background: #ff475726;
        color: #ff4757;

        button {
            background-color: #ff4757;
            border-color: #ff4757;
        }
    }
}

#mainpinpad {
    text-align: center;

    .instrucciones {
        margin: 10px;
        transition: 0.5s;

        &.red {
            color: rgba(255, 0, 0, 0.699);
        }
    }

    .avatar {
        border-radius: 50%;
        margin: 10px;
        background: white;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
        padding: 3px;

        @media(max-width: 990px) {
            display: none;
        }
    }

    #contenedorSalida {
        display: flex;
        justify-content: center;
        margin: auto;
        border-radius: 5px;
        min-height: 50px;
        border: 1px solid #e9e9e9;
        width: 270px;
        margin-bottom: 25px;
        padding: 0px 10px;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 30px;
            font-size: 1.8rem;
            margin: 2px;
            padding: 4px;
        }
    }

    .dni-switch {
        margin: 30px 0px;
    }

    .el-switch__label.is-active {
        color: #000000;
    }
}

#mainopciones {
    text-align: center;

    #contenedorCards {
        width: 50%;
        margin: auto;
        transition: 0.5s;

        @media (max-width: 1600px) {
            width: 75%;
        }

        @media (max-width: 500px) {
            width: 100%;
        }

        #datosusuario {
            display: flex;
            padding: 10px;
            background: #ffffffb8;
            border: 3px solid #d3d3d361;
            border-radius: 10px;
            margin: auto;
            margin-bottom: 10px;
            justify-content: flex-start;
            transition: 0.5s;

            @media (min-width: 990px) {
                display: none
            }

            img {
                border-radius: 50%;
                margin-bottom: 10px;
                padding: 3px;
                margin: 10px 20px 10px 10px;
            }

            span {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: left;

                h2 {
                    font-weight: normal;
                    margin: 0;
                    margin-bottom: 0px;
                }

                h3 {
                    font-weight: normal;
                    margin: 0;
                }
            }
        }

        #contenedorCronometros {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-wrap: wrap;
            margin-bottom: 20px;

            .contador {
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
                background: white;
                border-radius: 50%;

                span {
                    position: absolute;
                    font-size: 2rem;
                    transition: 1s;
                }
            }
        }

        .contenedorSelector {
            display: flex;
            background: #ffffff;
            border-radius: 10px;
            margin: auto;
            justify-content: center;
            margin-bottom: 10px;

            &.pauseList {
                flex-wrap: wrap;

                div {
                    width: calc(50% - 30px);
                }

                .botonvolver {
                    width: 100%;
                    font-size: 1rem;
                    margin: 5px;
                    background-color: rgba(249, 202, 36, 0.15);
                    border: none;
                    color: rgb(249, 202, 36);

                    &.red {
                        background-color: #ffe4e6;
                        color: #ff4757;
                    }
                }
            }

            div {
                border-radius: 5px;
                width: 100%;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 5px;
                transition: 0.5s;

                button {
                    width: 50px;
                    height: 50px;
                    transition: 0.5s;

                    span {
                        i {
                            font-size: 1.5rem;
                        }

                        svg {
                            font-size: 1.5rem;
                        }
                    }
                }

                &.green {
                    background: #a3cb3826;
                    color: #A3CB38;

                    button {
                        background-color: #A3CB38;
                        border-color: #A3CB38;
                    }
                }

                &.yellow {
                    background: #f9ca2426;
                    color: #f9ca24;

                    button {
                        background-color: #f9ca24;
                        border-color: #f9ca24;
                    }
                }

                &.purple {
                    background: #dfe6e9;
                    color: #636e72;

                    button {
                        background-color: #636e72;
                        border-color: #636e72;
                    }
                }

                &.red {
                    background: #ff475726;
                    color: #ff4757;

                    button {
                        background-color: #ff4757;
                        border-color: #ff4757;
                    }
                }
            }
        }
    }
}

#mainselectortarea {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .contenedorbusqueda {
        @media(min-width: 480px) {
            width: 400px;
        }
    }

    .contenedorreceptores {
        @media(min-width: 480px) {
            width: 400px;
        }

        padding:10px;
        max-height: 50vh;
        overflow-y:auto;

        li {
            list-style: none;
            padding: 10px;
            background: #d5dae8;
            margin: 0px 0px 10px 0px;
            border-radius: 5px;
            color: #071c64;
            cursor: pointer;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                fill: #071c64;
                transition: 0.3s;
            }

            &:hover {
                background: #071c64;
                color: white;

                svg {
                    fill: white;
                }
            }

            &:active {
                background: #041038;
                color: white;
            }
        }

    }
}

#contenedorajustes {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: left;
    width: 50%;
    background: white;
    margin: auto;
    border-radius: 5px;
    position: relative;

    @media (max-width: 1600px) {
        width: 75%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }

    .datosusuario {
        display: flex;
        padding: 10px;
        border-radius: 10px;
        justify-content: flex-start;

        img {
            border-radius: 50%;
            margin-bottom: 10px;
            padding: 3px;
            margin: 10px 20px 10px 10px;
        }

        span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;

            h2 {
                font-weight: normal;
                margin: 0;
                margin-bottom: 0px;
            }

            h3 {
                font-weight: normal;
                margin: 0;
            }
        }
    }

    h3 {
        margin: 10px 0px 0px 0px;
    }

    div {
        display: flex;
        background: #ffffff;
        border-radius: 10px;
        margin-top: 10px;
        justify-content: center;
        margin-bottom: 10px;

        div {
            border-radius: 5px;
            width: 100%;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 5px;
            transition: 0.5s;

            button {
                width: 50px;
                height: 50px;
                transition: 0.5s;
            }
        }
    }
}

.botoncerrar {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px;
    border: 2px solid darkgray;
    color: darkgray;
    border-radius: 50%;
    font-size: 1.5rem;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    align-items: center;
    transition: 0.5s;

    &:hover {
        background: darkgray;
        color: white;
    }

    &:active {
        transition: 0s;
        background: rgb(104, 104, 104);
        color: white;
    }
}

h2 {
    color: #727272;
    font-weight: 500;
    margin-bottom: 50px;
}


.tarjetaempleado {
    width: 160px;
    height: 160px;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

ul.contenedorNumeros {
    display: flex;
    padding: 0px;
    margin: 0 auto;
    list-style: none;
    flex-wrap: wrap;
    max-width: 300px;
    justify-content: center;

    li {
        width: 80px;
        height: 80px;
        background-color: #f9f9f9;
        border: 1px solid #dedede;
        border-radius: 50%;
        display: flex;
        align-items: Center;
        justify-content: center;
        font-size: 1.5rem;
        margin: 5px;
        color: #585858;
        font-weight: 500;
        cursor: pointer;
        transition: .4s;

        &:hover {
            background-color: #dedede;
            border: 1px solid #a0a0a0;
        }

        &.danger {
            color: #F56C6C;
            background-color: rgb(253, 226, 226);
            border-color: #F56C6C;

            &:hover {
                background-color: #F56C6C;
                color: rgb(253, 226, 226);
            }
        }

        &.success {
            color: #67C23A;
            background-color: rgb(225, 243, 216);
            border-color: #67C23A;

            &:hover {
                color: rgb(225, 243, 216);
                background-color: #67C23A;
            }
        }
    }
}

.selectorcentro {
    width: 60%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    background-color: #f9f9f9;
    border: 1px solid #dedede;
    color: #585858;
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    transition: 1s;
    cursor: pointer;

    &.enabled {
        &:hover {
            background-color: #dedede;
            border-color: #f9f9f9;
        }
    }

    &.disabled {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        cursor: not-allowed;
    }
}

h1 {
    margin: auto;
    color: gray;
}

.selectorestado {
    border-radius: 50%;
    border: 3px solid transparent;
    background: rgba(0, 0, 0, 0.5);
    width: 50px;
    height: 50px;
    position: relative;
    opacity: 0;
    transition: 0.3s;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 1;
    }

    &.selected {
        opacity: 1;
    }

    &:active {
        background: rgba(0, 0, 0, 0.5);
    }
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.apply-shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

/* Tooltip container */
.tooltip {
    position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
    opacity: 0;
    width: 250px;
    background-color: rgba(0, 0, 0, 0.795);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 0px 0px 5px 5px;
    position: absolute;
    z-index: 99999;
    width: 180px;
    top: 150px;
    left: 50%;
    margin-left: -90px;
    transition: 0.5s;
    /* Use half of the width (120/2 = 60), to center the tooltip */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    opacity: 1;
}

.el-loading-mask {
    background-color: none;
}
</style>